html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif, 'Open Sans', sans-serif,
    'Open Sans Condensed', sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
  padding: 0;
}